import { mapState } from "vuex"
import { addDoc } from "firebase/firestore"

export default {
  computed: {
    ...mapState(["firebaseGetters"]),
  },
  methods: {
    async addEntryToItemsLog(payload) {
      await addDoc(this.firebaseGetters.inventoryitemslogRef, payload) // This ref is undefined in this function.
    },
  },
}
