<template>
  <div>
    <v-row justify="start" class="mt-2 mb-1">
      <v-col cols="12">
        <h2 class="text-uppercase page-title mb-5">Write Off Inventory</h2>
      </v-col>
    </v-row>
    <div class="inpage-form-wrapper">
      <v-row justify="start" class="ma-2 inpage-form-form">
        <v-col cols="12">
          <v-row justify="start" class="mt-3">
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="itemId"
                :items="items"
                item-text="name"
                item-value="itemID"
                label="Ingredient/Recipe name"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="6">
              <measurement-field
                v-model="measurement"
                allowUnitSelection
                :item="inventoryItem"
                label="Quantity"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <custom-date-picker v-model="date" init />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="stage"
                :items="stageOptions"
                label="Write-off stage"
                outlined
              />
            </v-col>
          </v-row>

          <v-row justify="start" class="mt-2">
            <v-col cols="12">
              <v-textarea v-model="comment" label="Comment / Reason" outlined />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-footer fixed elevation="3" class="fixed-btn-container py-4">
      <v-btn color="primary" class="fixed-btn" @click="submitHandle">
        Submit Write-Off
      </v-btn>
    </v-footer>
  </div>
</template>

<script>
import moment from "moment"
import { doc, writeBatch, runTransaction } from "firebase/firestore"
import db from "@/firebaseConfig"
import { mapState } from "vuex"
import CustomDatePicker from "@/AuthenticatedContent/shared/forms/datetime/CustomDatePicker.vue"
import addEntryToItemsLog from "@/mixins/logItem.js"
import MeasurementField from "@/AuthenticatedContent/shared/forms/MeasurementField.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins"

export default {
  name: "add-form",
  components: {
    CustomDatePicker,
    MeasurementField,
  },
  mixins: [addEntryToItemsLog, topLevelMixins],
  data() {
    return {
      writeoffId: null,
      date: "",
      itemId: "",
      measurement: this.createEmptyMeasurementObject(),
      comment: null,
      stage: null,
      remainingQuantity: null,
      stageOptions: ["Receiving", "Preparation", "Storage", "Delivery"],
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "supplierItems", "items"]),
    formattedDate() {
      return this.date ? moment(this.date).format("dddd, MMMM Do YYYY") : ""
    },
    inventoryItem() {
      return this.items.find(item => item.itemID === this.itemId)
    },
  },
  watch: {
    visible(isVisible) {
      if (!isVisible) {
        this.clearFields()
        this.$emit("close")
      }
    },
  },
  methods: {
    async submitHandle() {
      await runTransaction(db, async transaction => {
        const batch = writeBatch(db)
        // Create the writeoffs entry
        this.writeoffId = doc(this.firebaseRefs.writeoffsRef).id
        batch.set(doc(this.firebaseRefs.writeoffsRef, this.writeoffId), {
          date: this.date,
          itemID: this.itemId,
          measurement: this.measurement,
          comment: this.comment,
          lifecycleStage: this.stage,
        })
        const inventoryItem = this.inventoryItem

        let currentItemQuantity = {}
        const itemDocRef = doc(this.firebaseRefs.itemsRef, inventoryItem.itemID)
        const itemDoc = await transaction.get(itemDocRef)
        currentItemQuantity =
          itemDoc.data().measurement || this.createEmptyMeasurementObject()

        // Handle changes to inventory items and inventory item logs
        if (inventoryItem.itemID) {
          const itemDocRef = doc(this.firebaseRefs.itemsRef, inventoryItem.itemID)
          const prevQuantity = currentItemQuantity.quantity || 0
          const quantity =
            parseFloat(prevQuantity) - parseFloat(this.measurement.quantity)

          // Update the inventory item
          transaction.update(itemDocRef, {
            measurement: {
              quantity: quantity,
              unit: inventoryItem.measurement.unit,
              preferredDisplayUnitId:
                inventoryItem.measurement.preferredDisplayUnitId,
            },
          })

          // Add the inventory item log entry
          const inventoryItemLogId = doc(this.firebaseRefs.inventoryitemslogRef).id
          batch.set(
            doc(this.firebaseRefs.inventoryitemslogRef, inventoryItemLogId),
            {
              id: inventoryItemLogId,
              itemID: inventoryItem.itemID,
              receivingID: this.writeoffId,
              prevMeasurement: {
                quantity: prevQuantity,
                unit: inventoryItem.measurement.unit,
                preferredDisplayUnitId:
                  inventoryItem.measurement.preferredDisplayUnitId,
              },
              measurement: {
                quantity: quantity,
                unit: inventoryItem.measurement.unit,
                preferredDisplayUnitId:
                  inventoryItem.measurement.preferredDisplayUnitId,
              },
              delta: quantity - prevQuantity,
              date: this.date,
              triggerType: "Writeoff",
              unit: inventoryItem.measurement.preferredDisplayUnitId,
            }
          )
        }

        await batch.commit().then(() => {
          this.awaitingResponse = false
          this.$emit("success")
        })
      })

      this.clearFields()
    },
    getItemCost(itemID) {
      try {
        return this.supplierItems.find(item => item.linkedItemID == itemID).costMoney
      } catch (e) {
        console.error("Error: ", e)
      }
    },
    clearFields() {
      this.measurement = this.createEmptyMeasurementObject()
      this.itemId =
        this.user =
        this.manager =
        this.comment =
        this.stage =
        this.writeoffId =
        this.remainingQuantity =
          null
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss">
.inpage-form {
  &-wrapper {
    background: var(--v-light-background-base);
    padding: 5px 0;
    border-radius: 15px;
    border: solid 1px var(--v-light-border-base);

    .v-input__control .v-input__slot {
      background-color: var(--v-background-base) !important;
    }
  }
}
</style>
