<template>
  <div>
    <page-component
      pageTitle="Write-Off History"
      :headers="headers"
      :items="writeoffs"
      :buttons="[
        { text: '+ Add New Write-off', to: { params: { tab: 'AddEditForm' } } },
      ]"
      :loadingData="!loaded"
    >
      <template v-slot:[`item.quantity`]="props">
        <span v-if="props.item.measurement">
          {{
            getQuantityInBaseUnits(props.item.measurement.quantity, props.item.id) +
            " " +
            getUnitName(props.item.measurement.preferredDisplayUnitId) +
            "s"
          }}
        </span>
      </template>
    </page-component>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"

export default {
  name: "writeoff-history",
  components: {
    PageComponent,
  },
  mixins: [topLevelMixins],
  data() {
    return {
      headers: [
        { text: "Date", value: "date", class: "header-style" },
        { text: "Item", value: "itemID", class: "header-style" },
        { text: "Amount", value: "quantity", class: "header-style" },
        { text: "Stage", value: "lifecycleStage" },
        { text: "Comment", value: "comment", class: "header-style" },
      ],
    }
  },
  computed: {
    ...mapGetters(["getUnitName"]),
    ...mapState(["units", "writeoffs"]),
    ...mapState(["firebaseRefs"]),
  },
  methods: {
    handleSuccess() {
      this.$router.push({ path: "/writeoffs" })
    },
  },
}
</script>
